import "./styles/App.css";
import React, { useEffect, useMemo } from "react";
import WebFont from "webfontloader";

const iosPlatforms = ["iPhone", "iPad", "iPod"];

function App() {
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;
  const userAgent =
    window.navigator.userAgent || navigator.vendor || window.opera;
  const artistName = window.location.pathname.split("/")[1];

  // const isInstagramInAppBrowser = userAgent.match(/Instagram/i);

  const currentPlatform = useMemo(() => {
    if (iosPlatforms.indexOf(platform) > -1) {
      return "iOS";
    } else if (/Android/.test(userAgent)) {
      return "Android";
    } else {
      return "Other";
    }
  }, []);

  const redirectUrl = useMemo(() => {
    if (currentPlatform === "iOS") {
      return "itms-apps://apple.com/app/1574343198";
    } else if (currentPlatform === "Android") {
      return "https://play.google.com/store/apps/details?id=com.gotattoo";
    } else {
      return "https://gotattoo.co/";
    }
  }, [currentPlatform]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter:400,500,600,700"],
      },
    });
  }, []);

  if (!currentPlatform || !redirectUrl) {
    return null;
  }

  if (currentPlatform === "Other") {
    window.location.replace(redirectUrl);
    return null;
  }

  return (
    <div className={"containerCenter"} style={{ padding: 10, height: "100vh" }}>
      <div
        style={{
          backgroundColor: "#FA6767",
          color: "white",
          padding: 10,
          borderRadius: 10,
        }}
        onClick={() => {
          window.location.replace(redirectUrl);
        }}
      >
        Télécharger GoTattoo sur {currentPlatform}
      </div>

      <div style={{ marginTop: 15, padding: 5 }}>
        {"Vous possédez déjà l'application ?"}
      </div>

      <div
        style={{
          backgroundColor: "#FA6767",
          color: "white",
          padding: 10,
          borderRadius: 10,
        }}
        onClick={() => {
          window.location.replace("gotattoo://" + encodeURI(artistName));
        }}
      >
        Aller sur le profil de {artistName}
      </div>
    </div>
  );
}

export default App;
